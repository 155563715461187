var exports = {};
exports = {
  A: {
    A: {
      J: 0.0131217,
      D: 0.00621152,
      E: 0.0440661,
      F: 0.0881323,
      A: 0.00734435,
      B: 0.440661,
      "6B": 0.009298
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "6B", "J", "D", "E", "F", "A", "B", "", "", ""],
    E: "IE",
    F: {
      "6B": 962323200,
      J: 998870400,
      D: 1161129600,
      E: 1237420800,
      F: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      C: 0.007948,
      K: 0.004267,
      L: 0.004268,
      G: 0.003974,
      M: 0.003702,
      N: 0.003974,
      O: 0.01987,
      P: 0,
      Q: 0.004298,
      R: 0.00944,
      S: 0.004043,
      T: 0.003974,
      U: 0.003974,
      V: 0.003974,
      W: 0.003974,
      X: 0.004318,
      Y: 0.003974,
      Z: 0.004118,
      a: 0.003939,
      b: 0.007948,
      e: 0.004118,
      f: 0.003939,
      g: 0.003801,
      h: 0.003929,
      i: 0.003855,
      j: 0.003929,
      k: 0.003974,
      l: 0.003974,
      m: 0.011922,
      n: 0.011922,
      o: 0.035766,
      p: 0.067558,
      q: 0.802748,
      c: 3.07588,
      H: 0.246388
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "K", "L", "G", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "c", "H", "", "", ""],
    E: "Edge",
    F: {
      C: 1438128000,
      K: 1447286400,
      L: 1470096000,
      G: 1491868800,
      M: 1508198400,
      N: 1525046400,
      O: 1542067200,
      P: 1579046400,
      Q: 1581033600,
      R: 1586736000,
      S: 1590019200,
      T: 1594857600,
      U: 1598486400,
      V: 1602201600,
      W: 1605830400,
      X: 1611360000,
      Y: 1614816000,
      Z: 1618358400,
      a: 1622073600,
      b: 1626912000,
      e: 1630627200,
      f: 1632441600,
      g: 1634774400,
      h: 1637539200,
      i: 1641427200,
      j: 1643932800,
      k: 1646265600,
      l: 1649635200,
      m: 1651190400,
      n: 1653955200,
      o: 1655942400,
      p: 1659657600,
      q: 1661990400,
      c: 1664755200,
      H: 1666915200
    },
    D: {
      C: "ms",
      K: "ms",
      L: "ms",
      G: "ms",
      M: "ms",
      N: "ms",
      O: "ms"
    }
  },
  C: {
    A: {
      "0": 0.004393,
      "1": 0.004418,
      "2": 0.008834,
      "3": 0.008322,
      "4": 0.008928,
      "5": 0.004471,
      "6": 0.009284,
      "7": 0.004707,
      "8": 0.009076,
      "9": 0.007948,
      "7B": 0.004118,
      qB: 0.004271,
      I: 0.015896,
      r: 0.004879,
      J: 0.020136,
      D: 0.005725,
      E: 0.004525,
      F: 0.00533,
      A: 0.004283,
      B: 0.007948,
      C: 0.004471,
      K: 0.004486,
      L: 0.00453,
      G: 0.008322,
      M: 0.004417,
      N: 0.004425,
      O: 0.004161,
      s: 0.004443,
      t: 0.004283,
      u: 0.008322,
      v: 0.013698,
      w: 0.004161,
      x: 0.008786,
      y: 0.004118,
      z: 0.004317,
      AB: 0.004783,
      BB: 0.003929,
      CB: 0.004783,
      DB: 0.00487,
      EB: 0.005029,
      FB: 0.0047,
      GB: 0.015896,
      HB: 0.007948,
      IB: 0.003867,
      JB: 0.004525,
      KB: 0.004293,
      LB: 0.003702,
      MB: 0.004538,
      NB: 0.008282,
      OB: 0.011601,
      PB: 0.051662,
      QB: 0.011601,
      RB: 0.003929,
      SB: 0.003974,
      TB: 0.003974,
      UB: 0.011601,
      VB: 0.003939,
      rB: 0.003855,
      WB: 0.003929,
      sB: 0.004356,
      XB: 0.004425,
      YB: 0.008322,
      ZB: 0.00415,
      aB: 0.004267,
      bB: 0.003801,
      cB: 0.004267,
      dB: 0.007948,
      eB: 0.00415,
      fB: 0.004293,
      gB: 0.004425,
      d: 0.003974,
      hB: 0.00415,
      iB: 0.00415,
      jB: 0.004318,
      kB: 0.004356,
      lB: 0.003974,
      mB: 0.035766,
      P: 0.003974,
      Q: 0.007948,
      R: 0.007948,
      tB: 0.003974,
      S: 0.003974,
      T: 0.003929,
      U: 0.004268,
      V: 0.003801,
      W: 0.007948,
      X: 0.007948,
      Y: 0.003974,
      Z: 0.003974,
      a: 0.03974,
      b: 0.003801,
      e: 0.003855,
      f: 0.015896,
      g: 0.003974,
      h: 0.003974,
      i: 0.003974,
      j: 0.003974,
      k: 0.011922,
      l: 0.007948,
      m: 0.011922,
      n: 0.063584,
      o: 0.043714,
      p: 0.071532,
      q: 1.50615,
      c: 0.679554,
      H: 0.007948,
      uB: 0,
      vB: 0,
      "8B": 0.008786,
      "9B": 0.00487
    },
    B: "moz",
    C: ["7B", "qB", "8B", "9B", "I", "r", "J", "D", "E", "F", "A", "B", "C", "K", "L", "G", "M", "N", "O", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "rB", "WB", "sB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "d", "hB", "iB", "jB", "kB", "lB", "mB", "P", "Q", "R", "tB", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "c", "H", "uB", "vB", ""],
    E: "Firefox",
    F: {
      "0": 1391472000,
      "1": 1395100800,
      "2": 1398729600,
      "3": 1402358400,
      "4": 1405987200,
      "5": 1409616000,
      "6": 1413244800,
      "7": 1417392000,
      "8": 1421107200,
      "9": 1424736000,
      "7B": 1161648000,
      qB: 1213660800,
      "8B": 1246320000,
      "9B": 1264032000,
      I: 1300752000,
      r: 1308614400,
      J: 1313452800,
      D: 1317081600,
      E: 1317081600,
      F: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      K: 1335225600,
      L: 1338854400,
      G: 1342483200,
      M: 1346112000,
      N: 1349740800,
      O: 1353628800,
      s: 1357603200,
      t: 1361232000,
      u: 1364860800,
      v: 1368489600,
      w: 1372118400,
      x: 1375747200,
      y: 1379376000,
      z: 1386633600,
      AB: 1428278400,
      BB: 1431475200,
      CB: 1435881600,
      DB: 1439251200,
      EB: 1442880000,
      FB: 1446508800,
      GB: 1450137600,
      HB: 1453852800,
      IB: 1457395200,
      JB: 1461628800,
      KB: 1465257600,
      LB: 1470096000,
      MB: 1474329600,
      NB: 1479168000,
      OB: 1485216000,
      PB: 1488844800,
      QB: 1492560000,
      RB: 1497312000,
      SB: 1502150400,
      TB: 1506556800,
      UB: 1510617600,
      VB: 1516665600,
      rB: 1520985600,
      WB: 1525824000,
      sB: 1529971200,
      XB: 1536105600,
      YB: 1540252800,
      ZB: 1544486400,
      aB: 1548720000,
      bB: 1552953600,
      cB: 1558396800,
      dB: 1562630400,
      eB: 1567468800,
      fB: 1571788800,
      gB: 1575331200,
      d: 1578355200,
      hB: 1581379200,
      iB: 1583798400,
      jB: 1586304000,
      kB: 1588636800,
      lB: 1591056000,
      mB: 1593475200,
      P: 1595894400,
      Q: 1598313600,
      R: 1600732800,
      tB: 1603152000,
      S: 1605571200,
      T: 1607990400,
      U: 1611619200,
      V: 1614038400,
      W: 1616457600,
      X: 1618790400,
      Y: 1622505600,
      Z: 1626134400,
      a: 1628553600,
      b: 1630972800,
      e: 1633392000,
      f: 1635811200,
      g: 1638835200,
      h: 1641859200,
      i: 1644364800,
      j: 1646697600,
      k: 1649116800,
      l: 1651536000,
      m: 1653955200,
      n: 1656374400,
      o: 1658793600,
      p: 1661212800,
      q: 1663632000,
      c: 1666051200,
      H: 1668470400,
      uB: null,
      vB: null
    }
  },
  D: {
    A: {
      "0": 0.004326,
      "1": 0.0047,
      "2": 0.004538,
      "3": 0.008322,
      "4": 0.008596,
      "5": 0.004566,
      "6": 0.004118,
      "7": 0.007948,
      "8": 0.003702,
      "9": 0.004335,
      I: 0.004706,
      r: 0.004879,
      J: 0.004879,
      D: 0.005591,
      E: 0.005591,
      F: 0.005591,
      A: 0.004534,
      B: 0.004464,
      C: 0.010424,
      K: 0.0083,
      L: 0.004706,
      G: 0.015087,
      M: 0.004393,
      N: 0.004393,
      O: 0.008652,
      s: 0.008322,
      t: 0.004393,
      u: 0.004317,
      v: 0.003974,
      w: 0.008786,
      x: 0.003939,
      y: 0.004461,
      z: 0.004141,
      AB: 0.004464,
      BB: 0.015896,
      CB: 0.003867,
      DB: 0.015896,
      EB: 0.003974,
      FB: 0.003974,
      GB: 0.007948,
      HB: 0.007948,
      IB: 0.003974,
      JB: 0.003867,
      KB: 0.007948,
      LB: 0.01987,
      MB: 0.047688,
      NB: 0.003867,
      OB: 0.003929,
      PB: 0.003974,
      QB: 0.007948,
      RB: 0.003867,
      SB: 0.003974,
      TB: 0.035766,
      UB: 0.003974,
      VB: 0.003702,
      rB: 0.003702,
      WB: 0.011922,
      sB: 0.011922,
      XB: 0.003974,
      YB: 0.007948,
      ZB: 0.003929,
      aB: 0.011922,
      bB: 0.027818,
      cB: 0.007948,
      dB: 0.007948,
      eB: 0.047688,
      fB: 0.023844,
      gB: 0.011922,
      d: 0.03974,
      hB: 0.011922,
      iB: 0.031792,
      jB: 0.043714,
      kB: 0.035766,
      lB: 0.011922,
      mB: 0.031792,
      P: 0.107298,
      Q: 0.035766,
      R: 0.03974,
      S: 0.067558,
      T: 0.051662,
      U: 0.083454,
      V: 0.083454,
      W: 0.083454,
      X: 0.01987,
      Y: 0.03974,
      Z: 0.023844,
      a: 0.055636,
      b: 0.047688,
      e: 0.043714,
      f: 0.035766,
      g: 0.023844,
      h: 0.063584,
      i: 0.05961,
      j: 0.051662,
      k: 0.055636,
      l: 0.151012,
      m: 0.087428,
      n: 0.143064,
      o: 0.421244,
      p: 0.41727,
      q: 6.00869,
      c: 15.7013,
      H: 0.643788,
      uB: 0.01987,
      vB: 0.011922,
      AC: 0,
      BC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "I", "r", "J", "D", "E", "F", "A", "B", "C", "K", "L", "G", "M", "N", "O", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "rB", "WB", "sB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "d", "hB", "iB", "jB", "kB", "lB", "mB", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "c", "H", "uB", "vB", "AC", "BC"],
    E: "Chrome",
    F: {
      "0": 1364428800,
      "1": 1369094400,
      "2": 1374105600,
      "3": 1376956800,
      "4": 1384214400,
      "5": 1389657600,
      "6": 1392940800,
      "7": 1397001600,
      "8": 1400544000,
      "9": 1405468800,
      I: 1264377600,
      r: 1274745600,
      J: 1283385600,
      D: 1287619200,
      E: 1291248000,
      F: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      K: 1312243200,
      L: 1316131200,
      G: 1316131200,
      M: 1319500800,
      N: 1323734400,
      O: 1328659200,
      s: 1332892800,
      t: 1337040000,
      u: 1340668800,
      v: 1343692800,
      w: 1348531200,
      x: 1352246400,
      y: 1357862400,
      z: 1361404800,
      AB: 1409011200,
      BB: 1412640000,
      CB: 1416268800,
      DB: 1421798400,
      EB: 1425513600,
      FB: 1429401600,
      GB: 1432080000,
      HB: 1437523200,
      IB: 1441152000,
      JB: 1444780800,
      KB: 1449014400,
      LB: 1453248000,
      MB: 1456963200,
      NB: 1460592000,
      OB: 1464134400,
      PB: 1469059200,
      QB: 1472601600,
      RB: 1476230400,
      SB: 1480550400,
      TB: 1485302400,
      UB: 1489017600,
      VB: 1492560000,
      rB: 1496707200,
      WB: 1500940800,
      sB: 1504569600,
      XB: 1508198400,
      YB: 1512518400,
      ZB: 1516752000,
      aB: 1520294400,
      bB: 1523923200,
      cB: 1527552000,
      dB: 1532390400,
      eB: 1536019200,
      fB: 1539648000,
      gB: 1543968000,
      d: 1548720000,
      hB: 1552348800,
      iB: 1555977600,
      jB: 1559606400,
      kB: 1564444800,
      lB: 1568073600,
      mB: 1571702400,
      P: 1575936000,
      Q: 1580860800,
      R: 1586304000,
      S: 1589846400,
      T: 1594684800,
      U: 1598313600,
      V: 1601942400,
      W: 1605571200,
      X: 1611014400,
      Y: 1614556800,
      Z: 1618272000,
      a: 1621987200,
      b: 1626739200,
      e: 1630368000,
      f: 1632268800,
      g: 1634601600,
      h: 1637020800,
      i: 1641340800,
      j: 1643673600,
      k: 1646092800,
      l: 1648512000,
      m: 1650931200,
      n: 1653350400,
      o: 1655769600,
      p: 1659398400,
      q: 1661817600,
      c: 1664236800,
      H: 1666656000,
      uB: 1669680000,
      vB: null,
      AC: null,
      BC: null
    }
  },
  E: {
    A: {
      I: 0,
      r: 0.008322,
      J: 0.004656,
      D: 0.004465,
      E: 0.003974,
      F: 0.003929,
      A: 0.004425,
      B: 0.004318,
      C: 0.003801,
      K: 0.023844,
      L: 0.11922,
      G: 0.027818,
      CC: 0,
      wB: 0.008692,
      DC: 0.011922,
      EC: 0.00456,
      FC: 0.004283,
      GC: 0.015896,
      xB: 0.007948,
      nB: 0.01987,
      oB: 0.035766,
      yB: 0.313946,
      HC: 0.305998,
      IC: 0.051662,
      zB: 0.051662,
      "0B": 0.131142,
      "1B": 0.266258,
      "2B": 1.63331,
      pB: 0.49675,
      "3B": 0.071532,
      JC: 0,
      KC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "CC", "wB", "I", "r", "DC", "J", "EC", "D", "FC", "E", "F", "GC", "A", "xB", "B", "nB", "C", "oB", "K", "yB", "L", "HC", "G", "IC", "zB", "0B", "1B", "2B", "pB", "3B", "JC", "KC", ""],
    E: "Safari",
    F: {
      CC: 1205798400,
      wB: 1226534400,
      I: 1244419200,
      r: 1275868800,
      DC: 1311120000,
      J: 1343174400,
      EC: 1382400000,
      D: 1382400000,
      FC: 1410998400,
      E: 1413417600,
      F: 1443657600,
      GC: 1458518400,
      A: 1474329600,
      xB: 1490572800,
      B: 1505779200,
      nB: 1522281600,
      C: 1537142400,
      oB: 1553472000,
      K: 1568851200,
      yB: 1585008000,
      L: 1600214400,
      HC: 1619395200,
      G: 1632096000,
      IC: 1635292800,
      zB: 1639353600,
      "0B": 1647216000,
      "1B": 1652745600,
      "2B": 1658275200,
      pB: 1662940800,
      "3B": 1666569600,
      JC: null,
      KC: null
    }
  },
  F: {
    A: {
      "0": 0.004393,
      "1": 0.007948,
      "2": 0.004879,
      "3": 0.004879,
      "4": 0.003974,
      "5": 0.005152,
      "6": 0.005014,
      "7": 0.009758,
      "8": 0.004879,
      "9": 0.003974,
      F: 0.0082,
      B: 0.016581,
      C: 0.004317,
      G: 0.00685,
      M: 0.00685,
      N: 0.00685,
      O: 0.005014,
      s: 0.006015,
      t: 0.004879,
      u: 0.006597,
      v: 0.006597,
      w: 0.013434,
      x: 0.006702,
      y: 0.006015,
      z: 0.005595,
      AB: 0.004283,
      BB: 0.004367,
      CB: 0.004534,
      DB: 0.007948,
      EB: 0.004227,
      FB: 0.004418,
      GB: 0.004161,
      HB: 0.004227,
      IB: 0.004725,
      JB: 0.011922,
      KB: 0.008942,
      LB: 0.004707,
      MB: 0.004827,
      NB: 0.004707,
      OB: 0.004707,
      PB: 0.004326,
      QB: 0.008922,
      RB: 0.014349,
      SB: 0.004425,
      TB: 0.00472,
      UB: 0.004425,
      VB: 0.004425,
      WB: 0.00472,
      XB: 0.004532,
      YB: 0.004566,
      ZB: 0.02283,
      aB: 0.00867,
      bB: 0.004656,
      cB: 0.004642,
      dB: 0.003929,
      eB: 0.00944,
      fB: 0.004293,
      gB: 0.003929,
      d: 0.004298,
      hB: 0.096692,
      iB: 0.004201,
      jB: 0.004141,
      kB: 0.004257,
      lB: 0.003939,
      mB: 0.008236,
      P: 0.003855,
      Q: 0.003939,
      R: 0.008514,
      tB: 0.003939,
      S: 0.003939,
      T: 0.003702,
      U: 0.011922,
      V: 0.003855,
      W: 0.003855,
      X: 0.003929,
      Y: 0.007948,
      Z: 0.405348,
      a: 0.862358,
      b: 0,
      LC: 0.00685,
      MC: 0,
      NC: 0.008392,
      OC: 0.004706,
      nB: 0.006229,
      "4B": 0.004879,
      PC: 0.008786,
      oB: 0.00472
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "F", "LC", "MC", "NC", "OC", "B", "nB", "4B", "PC", "C", "oB", "G", "M", "N", "O", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "eB", "fB", "gB", "d", "hB", "iB", "jB", "kB", "lB", "mB", "P", "Q", "R", "tB", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "", "", ""],
    E: "Opera",
    F: {
      "0": 1422316800,
      "1": 1425945600,
      "2": 1430179200,
      "3": 1433808000,
      "4": 1438646400,
      "5": 1442448000,
      "6": 1445904000,
      "7": 1449100800,
      "8": 1454371200,
      "9": 1457308800,
      F: 1150761600,
      LC: 1223424000,
      MC: 1251763200,
      NC: 1267488000,
      OC: 1277942400,
      B: 1292457600,
      nB: 1302566400,
      "4B": 1309219200,
      PC: 1323129600,
      C: 1323129600,
      oB: 1352073600,
      G: 1372723200,
      M: 1377561600,
      N: 1381104000,
      O: 1386288000,
      s: 1390867200,
      t: 1393891200,
      u: 1399334400,
      v: 1401753600,
      w: 1405987200,
      x: 1409616000,
      y: 1413331200,
      z: 1417132800,
      AB: 1462320000,
      BB: 1465344000,
      CB: 1470096000,
      DB: 1474329600,
      EB: 1477267200,
      FB: 1481587200,
      GB: 1486425600,
      HB: 1490054400,
      IB: 1494374400,
      JB: 1498003200,
      KB: 1502236800,
      LB: 1506470400,
      MB: 1510099200,
      NB: 1515024000,
      OB: 1517961600,
      PB: 1521676800,
      QB: 1525910400,
      RB: 1530144000,
      SB: 1534982400,
      TB: 1537833600,
      UB: 1543363200,
      VB: 1548201600,
      WB: 1554768000,
      XB: 1561593600,
      YB: 1566259200,
      ZB: 1570406400,
      aB: 1573689600,
      bB: 1578441600,
      cB: 1583971200,
      dB: 1587513600,
      eB: 1592956800,
      fB: 1595894400,
      gB: 1600128000,
      d: 1603238400,
      hB: 1613520000,
      iB: 1612224000,
      jB: 1616544000,
      kB: 1619568000,
      lB: 1623715200,
      mB: 1627948800,
      P: 1631577600,
      Q: 1633392000,
      R: 1635984000,
      tB: 1638403200,
      S: 1642550400,
      T: 1644969600,
      U: 1647993600,
      V: 1650412800,
      W: 1652745600,
      X: 1654646400,
      Y: 1657152000,
      Z: 1660780800,
      a: 1663113600,
      b: 1668816000
    },
    D: {
      F: "o",
      B: "o",
      C: "o",
      LC: "o",
      MC: "o",
      NC: "o",
      OC: "o",
      nB: "o",
      "4B": "o",
      PC: "o",
      oB: "o"
    }
  },
  G: {
    A: {
      E: 0,
      wB: 0,
      QC: 0,
      "5B": 0.00319488,
      RC: 0.00479232,
      SC: 0.00319488,
      TC: 0.014377,
      UC: 0.00479232,
      VC: 0.0159744,
      WC: 0.0607028,
      XC: 0.00319488,
      YC: 0.0718849,
      ZC: 0.0255591,
      aC: 0.0223642,
      bC: 0.0223642,
      cC: 0.408945,
      dC: 0.0287539,
      eC: 0.00958465,
      fC: 0.0383386,
      gC: 0.119808,
      hC: 0.354632,
      iC: 0.773162,
      jC: 0.201278,
      zB: 0.28115,
      "0B": 0.351437,
      "1B": 0.800318,
      "2B": 6.71564,
      pB: 4.55111,
      "3B": 0.241214
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "wB", "QC", "5B", "RC", "SC", "TC", "E", "UC", "VC", "WC", "XC", "YC", "ZC", "aC", "bC", "cC", "dC", "eC", "fC", "gC", "hC", "iC", "jC", "zB", "0B", "1B", "2B", "pB", "3B", "", "", ""],
    E: "Safari on iOS",
    F: {
      wB: 1270252800,
      QC: 1283904000,
      "5B": 1299628800,
      RC: 1331078400,
      SC: 1359331200,
      TC: 1394409600,
      E: 1410912000,
      UC: 1413763200,
      VC: 1442361600,
      WC: 1458518400,
      XC: 1473724800,
      YC: 1490572800,
      ZC: 1505779200,
      aC: 1522281600,
      bC: 1537142400,
      cC: 1553472000,
      dC: 1568851200,
      eC: 1572220800,
      fC: 1580169600,
      gC: 1585008000,
      hC: 1600214400,
      iC: 1619395200,
      jC: 1632096000,
      zB: 1639353600,
      "0B": 1647216000,
      "1B": 1652659200,
      "2B": 1658275200,
      pB: 1662940800,
      "3B": 1666569600
    }
  },
  H: {
    A: {
      kC: 1.02708
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "kC", "", "", ""],
    E: "Opera Mini",
    F: {
      kC: 1426464000
    }
  },
  I: {
    A: {
      qB: 0,
      I: 0.0223354,
      H: 0,
      lC: 0,
      mC: 0,
      nC: 0,
      oC: 0.0297805,
      "5B": 0.0893414,
      pC: 0,
      qC: 0.364811
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "lC", "mC", "nC", "qB", "I", "oC", "5B", "pC", "qC", "H", "", "", ""],
    E: "Android Browser",
    F: {
      lC: 1256515200,
      mC: 1274313600,
      nC: 1291593600,
      qB: 1298332800,
      I: 1318896000,
      oC: 1341792000,
      "5B": 1374624000,
      pC: 1386547200,
      qC: 1401667200,
      H: 1666828800
    }
  },
  J: {
    A: {
      D: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      D: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      d: 0.0111391,
      nB: 0,
      "4B": 0,
      oB: 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "nB", "4B", "C", "oB", "d", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      nB: 1314835200,
      "4B": 1318291200,
      C: 1330300800,
      oB: 1349740800,
      d: 1666828800
    },
    D: {
      d: "webkit"
    }
  },
  L: {
    A: {
      H: 40.2785
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "H", "", "", ""],
    E: "Chrome for Android",
    F: {
      H: 1666828800
    }
  },
  M: {
    A: {
      c: 0.283269
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "c", "", "", ""],
    E: "Firefox for Android",
    F: {
      c: 1666051200
    }
  },
  N: {
    A: {
      A: 0.0115934,
      B: 0.022664
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      rC: 0.638862
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "rC", "", "", ""],
    E: "UC Browser for Android",
    F: {
      rC: 1634688000
    },
    D: {
      rC: "webkit"
    }
  },
  P: {
    A: {
      I: 0.146868,
      sC: 0.0103543,
      tC: 0.010304,
      uC: 0.0629436,
      vC: 0.0103584,
      wC: 0.0104443,
      xB: 0.0105043,
      xC: 0.0314718,
      yC: 0.0104906,
      zC: 0.052453,
      "0C": 0.052453,
      "1C": 0.0314718,
      pB: 0.0944154,
      "2C": 0.157359,
      "3C": 2.15057,
      "4C": 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "I", "sC", "tC", "uC", "vC", "wC", "xB", "xC", "yC", "zC", "0C", "1C", "pB", "2C", "3C", "4C", "", "", ""],
    E: "Samsung Internet",
    F: {
      I: 1461024000,
      sC: 1481846400,
      tC: 1509408000,
      uC: 1528329600,
      vC: 1546128000,
      wC: 1554163200,
      xB: 1567900800,
      xC: 1582588800,
      yC: 1593475200,
      zC: 1605657600,
      "0C": 1618531200,
      "1C": 1629072000,
      pB: 1640736000,
      "2C": 1651708800,
      "3C": 1659657600,
      "4C": 1667260800
    }
  },
  Q: {
    A: {
      yB: 0.126567
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "yB", "", "", ""],
    E: "QQ Browser",
    F: {
      yB: 1663718400
    }
  },
  R: {
    A: {
      "5C": 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "5C", "", "", ""],
    E: "Baidu Browser",
    F: {
      "5C": 1663027200
    }
  },
  S: {
    A: {
      "6C": 0.042189
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "6C", "", "", ""],
    E: "KaiOS Browser",
    F: {
      "6C": 1527811200
    }
  }
};
export default exports;