import _browsers from "./browsers";
import _browserVersions from "./browserVersions";
import _agents from "../../data/agents";
var exports = {};
const browsers = _browsers.browsers;
const versions = _browserVersions.browserVersions;
const agentsData = _agents;

function unpackBrowserVersions(versionsData) {
  return Object.keys(versionsData).reduce((usage, version) => {
    usage[versions[version]] = versionsData[version];
    return usage;
  }, {});
}

exports.agents = Object.keys(agentsData).reduce((map, key) => {
  let versionsData = agentsData[key];
  map[browsers[key]] = Object.keys(versionsData).reduce((data, entry) => {
    if (entry === "A") {
      data.usage_global = unpackBrowserVersions(versionsData[entry]);
    } else if (entry === "C") {
      data.versions = versionsData[entry].reduce((list, version) => {
        if (version === "") {
          list.push(null);
        } else {
          list.push(versions[version]);
        }

        return list;
      }, []);
    } else if (entry === "D") {
      data.prefix_exceptions = unpackBrowserVersions(versionsData[entry]);
    } else if (entry === "E") {
      data.browser = versionsData[entry];
    } else if (entry === "F") {
      data.release_date = Object.keys(versionsData[entry]).reduce((map2, key2) => {
        map2[versions[key2]] = versionsData[entry][key2];
        return map2;
      }, {});
    } else {
      // entry is B
      data.prefix = versionsData[entry];
    }

    return data;
  }, {});
  return map;
}, {});
export default exports;
export const agents = exports.agents;